<template>
    <v-container>
        <p class="subtitle-1 font-weight-bold mt-4">COMPLETED TICKETS</p>
        <p>{{ getClientsFromAPI }}</p>
        <v-row class="mb-4">
            <v-col cols="6" md="3" v-for="(service, index) in services" :key="index">
                <v-card outlined flat class="completed-ticket-cards">
                    <v-card-subtitle>{{service.name}}</v-card-subtitle>
                    <v-card-title class="pt-0">{{ service.ticketCount }}</v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <DataTable :headers="headers" :items="items"></DataTable>
    </v-container>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex';
import DataTable from '../../components/DataTable'

export default {
    name: 'Dashboard',

    components: {
        DataTable,
    },
    data: () => ({
        headers: [
            {
                text: 'Name',
                value: 'name'
            },
            {
                text: 'Age',
                value: 'age'
            },
            {
                text: 'Gender',
                value: 'gender'
            },
            {
                text: 'Transaction Type',
                value: 'transactionType'
            },
            {
                text: 'Date',
                value: 'date'
            },
            {
                text: 'Priority Type',
                value: 'priorityType'
            },
            {
                text: 'Actions',
                value: 'actions'
            }
        ],

        items: [
            {
                name: 'Charles Clemente',
                age: 21,
                gender: 'Male',
                transactionType: 'Medical Checkup',
                date: '05-20-2023',
                priorityType: 'None',
                actions: null
            },
            {
                name: 'Prince Miranda',
                age: 18,
                gender: 'Male',
                transactionType: 'Medical Checkup',
                date: '05-20-2023',
                priorityType: 'None',
                actions: null
            },
            {
                name: 'Cathlyn Lapid',
                age: 20,
                gender: 'Female',
                transactionType: 'Medical Checkup',
                date: '05-20-2023',
                priorityType: 'None',
                actions: null
            },
            {
                name: 'Mae De Guzman',
                age: 16,
                gender: 'Female',
                transactionType: 'Medical Checkup',
                date: '05-20-2023',
                priorityType: 'None',
                actions: null
            }
        ],

        services: [
            {
                name: 'Doctor 1',
                ticketCount: 147
            },
            {
                name: 'Doctor 2',
                ticketCount: 147
            },
            {
                name: 'Dental',
                ticketCount: 147
            },
            {
                name: 'Others',
                ticketCount: 147
            }
        ]
    }),
    computed: {
        ...mapGetters(['getClientsFromAPI'])
    }
}
</script>

<style scoped>

</style>
  