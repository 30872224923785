<template>

</template>

<script>

export default {
    name: 'Register',

    data: () => ({
        
    })
}
</script>