<template>
    <div>
        <v-dialog v-model="completeTicketData.completeTicketDialog" max-width="400">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" block :color="completeTicketData.completeTicketColor"
                    class="white--text mb-2" :disabled="currentClient !== null && isNext ? false : true">COMPLETE
                    TICKET</v-btn>
            </template>

            <v-card>
                <v-card-title id="complete-ticket-dialog-title" class="mb-4">COMPLETE CURRENT TICKET</v-card-title>
                <v-card-text>
                    <v-container class="pb-0">
                        <v-row v-if="currentClient && isNext">
                            <v-col cols="12" class="pa-0">
                                <v-text-field outlined dense readonly label="NAME"
                                    v-model="currentClient.name"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                                <v-text-field outlined dense readonly label="AGE"
                                    v-model="currentClient.age"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                                <v-text-field outlined dense readonly label="GENDER"
                                    v-model="currentClient.gender"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                                <v-text-field outlined dense readonly label="APPOINTMENT TYPE"
                                    v-model="currentClient.appointmentType"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                                <v-text-field outlined dense readonly label="PRIORITY TYPE"
                                    v-model="currentClient.priorityType"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="d-flex flex-column mx-2">
                    <v-btn class="mb-3 mx-auto white--text mt-2" block :color="completeTicketData.completeTicketColor"
                        @click="updateCurrentQueue()">COMPLETE TICKET</v-btn>
                    <v-btn class="mb-3 mx-auto" block plain @click="completeTicketData.completeTicketDialog = !completeTicketData.completeTicketDialog">CANCEL</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-btn block class="white--text mb-2" :color="nextTicketData.nextTicketColor" @click="callNextClient()">NEXT
            TICKET</v-btn>

        <v-dialog v-model="createTicketData.createTicketDialog" max-width="400">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" block :color="createTicketData.createTicketColor" class="white--text mb-2"
                    @click="getServiceDetails({ service: $route.meta.name, theme: $route.meta.theme, key: $route.meta.firebaseKey })">CREATE
                    TICKET</v-btn>
            </template>

            <v-card>
                <v-card-title id="create-ticket-dialog-title" class="mb-4">CREATE TICKET</v-card-title>
                <v-card-text>
                    <v-container class="pb-0">
                        <v-row>
                            <v-col cols="12" class="pa-0">
                                <v-text-field outlined dense label="NAME" v-model="newTicketData.name"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                                <v-text-field outlined dense label="AGE" v-model="newTicketData.age"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                                <v-select outlined dense label="GENDER" v-model="newTicketData.gender"
                                    :items="genderList"></v-select>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                                <v-text-field outlined dense label="APPOINTMENT TYPE"
                                    v-model="newTicketData.appointmentType"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                                <v-select outlined dense label="PRIORITY TYPE" v-model="newTicketData.priorityType"
                                    :items="priorityTypeList"></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="d-flex flex-column mx-2">
                    <v-btn class="mb-3 mx-auto white--text mt-2" block :color="createTicketData.createTicketColor"
                        @click="createTicket()">SAVE
                        TICKET</v-btn>
                    <v-btn class="mb-3 mx-auto" block plain
                        @click="createTicketData.createTicketDialog = !createTicketData.createTicketDialog">CANCEL</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'TicketForm',
    props: {
        currentClient: Object,
        nextClient: Object,
        isNext: Boolean
    },

    data: () => ({
        completeTicketData: {
            completeTicketColor: "#4E31AA",
            completeTicketDialog: false,
        },

        nextTicketData: {
            nextTicketColor: "#2F58CD",
            nextTicketDialog: false,
        },

        createTicketData: {
            createTicketColor: "#3795BD",
            createTicketDialog: false,
        },

        genderList: ['Male', 'Female'],
        priorityTypeList: ['Senior Citizen', 'PWD', 'None'],

        serviceDetails: {
            serviceName: '',
            serviceKey: '',
            currentQueue: [],
        },

        newTicketData: {
            name: null,
            age: null,
            gender: null,
            appointmentType: null,
            priorityType: null,
            code: null
        }
    }),
    computed: {
        ...mapGetters(['getServices', 'getAllQueues', 'getIsNext', 'getBaseURL']),

        currentRouteQueue() {
            switch (this.$route.meta.name) {
                case 'Doctor 1':
                    return this.getAllQueues.doctorOne

                case 'Doctor 2':
                    return this.getAllQueues.doctorTwo

                case 'Dental':
                    return this.getAllQueues.dental

                case 'Other':
                    return this.getAllQueues.other

                default:
                    break;
            }
        }
    },
    methods: {
        ...mapActions(['setDataToQueue', 'getServiceDetails', 'getAllQueueData', 'updateQueue', 'getNextClient', 'setNoCurrentClient', 'getClientsFromAPI', 'setNextClient', 'saveClientToAPI']),

        createTicket: function () {
            let lastTicket
            let codeArr
            let codeNum
            this.serviceDetails.serviceName = this.$route.meta.name
            this.serviceDetails.serviceKey = this.$route.meta.firebaseKey

            this.getServices.forEach(data => {
                if (data.service === this.serviceDetails.serviceName) {
                    this.serviceDetails.currentQueue = data.queue
                    if (data.queue.length > 0) {
                        lastTicket = this.serviceDetails.currentQueue[this.serviceDetails.currentQueue.length - 1]
                        codeArr = lastTicket.code.split('').splice(2).join('')
                        codeNum = parseInt(codeArr) + 1

                        switch (this.serviceDetails.serviceName) {
                            case 'Doctor 1':
                                this.newTicketData.code = 'DA' + codeNum
                                break;

                            case 'Doctor 2':
                                this.newTicketData.code = 'DB' + codeNum
                                break;

                            case 'Dental':
                                this.newTicketData.code = 'DN' + codeNum
                                break;

                            case 'Other':
                                this.newTicketData.code = 'OT' + codeNum
                                break;

                            default:
                                break;
                        }

                    } else {
                        switch (this.serviceDetails.serviceName) {
                            case 'Doctor 1':
                                this.newTicketData.code = 'DA1'
                                break;

                            case 'Doctor 2':
                                this.newTicketData.code = 'DB1'
                                break;

                            case 'Dental':
                                this.newTicketData.code = 'DN1'
                                break;

                            case 'Other':
                                this.newTicketData.code = 'OT1'
                                break;

                            default:
                                break;
                        }
                    }
                }
            })



            const payload = {
                key: this.serviceDetails.serviceKey,
                queue: this.serviceDetails.currentQueue,
                newTicket: this.newTicketData
            };

            this.setDataToQueue(payload)
            this.createTicketData.createTicketDialog = false

            this.newTicketData.name = null
            this.newTicketData.age = null
            this.newTicketData.gender = null
            this.newTicketData.appointmentType = null
            this.newTicketData.priorityType = null
            this.newTicketData.code = null
        },

        updateCurrentQueue: function () {
            this.completeTicketData.completeTicketDialog = false
            this.setNoCurrentClient({key: this.$route.meta.firebaseKey})
            this.updateQueue({ key: this.$route.meta.firebaseKey, currentClient: this.currentClient, currentQueue: this.currentRouteQueue })
            this.saveClientToAPI({ client: this.currentClient, url: this.getBaseURL.url })
            this.getClientsFromAPI({url: this.getBaseURL.url})
            this.completeTicketData.completeTicketDialog = false
        },

        callNextClient: function () {
            this.setNextClient({ client: this.currentClient, key: this.$route.meta.firebaseKey })
            this.getNextClient({ client: this.currentClient, key: this.$route.meta.firebaseKey })
        }
    }
}
</script>

<style scoped>
#complete-ticket-dialog-title {
    color: #4E31AA;
}

#create-ticket-dialog-title {
    color: #3795BD;
}
</style>
