<template>
    <div class="mb-4">
        <v-card flat>
            <v-card flat :color="$route.meta.theme">
                <v-card-subtitle class="overline white--text pt-1">CURRENT TICKET</v-card-subtitle>
                <v-card-title class="white--text pb-1 pt-0" v-if="clients.currentClient && clients.isNext">{{ clients.currentClient.code }}</v-card-title>
                <v-card-title class="white--text pb-1 pt-0" v-if="!clients.currentClient || !clients.isNext">NONE</v-card-title>
            </v-card>
            <v-card flat outlined class="my-4">
                <v-card-text class="d-flex flex-column">
                    <span><strong>NAME:</strong> {{clients.isNext && clients.currentClient ? clients.currentClient.name : null }}</span>
                    <span><strong>PRIORITY TYPE:</strong> {{clients.isNext && clients.currentClient ?  clients.currentClient.priorityType :null}}</span>
                    <span><strong>GENDER:</strong> {{clients.isNext && clients.currentClient ?  clients.currentClient.gender :null}}</span>
                    <span><strong>AGE:</strong> {{clients.isNext && clients.currentClient ?  clients.currentClient.age :null}}</span>
                </v-card-text>
            </v-card>
            <v-card flat class="px-0">
                <TicketForm :currentClient="clients.currentClient" :nextClient="clients.nextClient" :isNext="clients.isNext"></TicketForm>
            </v-card>
        </v-card>
    </div>
</template>
  
<script>
import TicketForm from '../Ticket/TicketForm'

export default {
    name: 'QueueDetail',
    props: {
        clients: Object
    },
    components: {
        TicketForm
    },

    data: () => ({
        mainColor: "#3A1078",
        currentTicketColor: "#4E31AA",
        nextTicketColor: "#2F58CD",
        newTicketColor: "#3795BD"
    })
}
</script>

<style scoped>
#card-title,
.next-ticket {
    color: #3A1078;
}

#queue-details-card,
#next-ticket-card {
    border: 1px solid #3A1078;
}
</style>
  