import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import { getDatabase, ref, set, onValue } from "firebase/database";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL: {
      url: null,
      isValid: false
    },
    currentClientDetails: {
      doctorOne: {
        client: 'client',
        isServing: false,
        isNext: false
      },
      doctorTwo: {
        client: 'client',
        isServing: false,
        isNext: false
      },
      dental: {
        client: 'client',
        isServing: false,
        isNext: false
      },
      other: {
        client: 'client',
        isServing: false,
        isNext: false
      }
    },
    isNext: {
      doctorOne: false,
      doctorTwo: false,
      dental: false,
      other: false
    },
    services: [],
    queues: {
      doctorOne: [],
      doctorTwo: [],
      dental: [],
      other: []
    },
    clientsFromApi: null
  },
  getters: {

    getServices: (state) => state.services,
    getAllQueues: (state) => state.queues,
    getIsNext: (state) => state.isNext,
    getCurrentClient: (state) => state.currentClientDetails,
    getBaseURL: (state) => state.baseURL,
    getClientsFromAPI: (state) => state.clientsFromApi

  },
  mutations: {

    SET_SERVICE_DATA: (state, payload) => {
      let hasQueue = false;

      if (state.services !== []) {

        state.services.forEach(queue => {

          if (queue.service === payload.service) {
            queue.queue = payload.queue
            hasQueue = true
          }

        })
      } else {
        state.services.push(payload)
      }

      if (!hasQueue) {
        state.services.push(payload)
      }
    },

    SET_ALL_QUEUE_DATA: (state, payload) => {
      switch (payload.service) {
        case 'Doctor 1':
          state.queues.doctorOne = payload.queue
          break;

        case 'Doctor 2':
          state.queues.doctorTwo = payload.queue
          break;

        case 'Dental':
          state.queues.dental = payload.queue
          break;

        case 'Other':
          state.queues.other = payload.queue
          break;

        default:
          break;
      }
    },

    SET_NEXT: (state, payload) => {
      switch (payload.key) {
        case 'doctorOne':
          state.isNext.doctorOne = payload.value
          break;
        case 'doctorTwo':
          state.isNext.doctorTwo = payload.value
          break;
        case 'dental':
          state.isNext.dental = payload.value
          break;
        case 'other':
          state.isNext.other = payload.value
          break;

        default:
          break;
      }
    },

    SET_CURRENT_CLIENT_DETAILS: (state, payload) => {
      const user = localStorage.getItem('token')
      switch (payload.service) {
        case 'doctorOne':
          if (user && !state.currentClientDetails.doctorOne.isServing && payload.isServing && !state.currentClientDetails.doctorOne.client && state.currentClientDetails.doctorOne.client !== 'client') {
            let utterance = new SpeechSynthesisUtterance('Now calling, ' + payload.client.name)
            utterance.rate = 1
            speechSynthesis.speak(utterance)
            speechSynthesis.speak(utterance)
          }
          state.currentClientDetails.doctorOne.client = payload.client
          state.currentClientDetails.doctorOne.isServing = payload.isServing
          break;
        case 'doctorTwo':
          if (user && !state.currentClientDetails.doctorTwo.isServing && payload.isServing && !state.currentClientDetails.doctorTwo.client && state.currentClientDetails.doctorTwo.client !== 'client') {
            let utterance = new SpeechSynthesisUtterance('Now calling, ' + payload.client.name)
            utterance.rate = 1
            speechSynthesis.speak(utterance)
            speechSynthesis.speak(utterance)
          }
          state.currentClientDetails.doctorTwo.client = payload.client
          state.currentClientDetails.doctorTwo.isServing = payload.isServing
          break;
        case 'dental':
          if (user && !state.currentClientDetails.dental.isServing && payload.isServing && !state.currentClientDetails.dental.client && state.currentClientDetails.dental.client !== 'client') {
            let utterance = new SpeechSynthesisUtterance('Now calling, ' + payload.client.name)
            utterance.rate = 1
            speechSynthesis.speak(utterance)
            speechSynthesis.speak(utterance)
          }
          state.currentClientDetails.dental.client = payload.client
          state.currentClientDetails.dental.isServing = payload.isServing
          break;
        case 'other':
          if (user && !state.currentClientDetails.other.isServing && payload.isServing && !state.currentClientDetails.other.client && state.currentClientDetails.other.client !== 'client') {
            let utterance = new SpeechSynthesisUtterance('Now calling, ' + payload.client.name)
            utterance.rate = 1
            speechSynthesis.speak(utterance)
            speechSynthesis.speak(utterance)
          }
          state.currentClientDetails.other.client = payload.client
          state.currentClientDetails.other.isServing = payload.isServing
          break;

        default:
          break;
      }
    },

    SET_BASE_URL: (state, payload) => {
      state.baseURL.url = payload.url
      state.baseURL.isValid = payload.isValid
    },

    SET_CLIENTS_FROM_API: (state, payload) => {
      state.clientsFromApi = payload
    }
  },
  actions: {

    // Action to add a new ticket to a queue in the Firebase Realtime Database
    setDataToQueue: ({ commit }, payload) => {
      const db = getDatabase()
      const reference = ref(db, 'services/' + payload.key)

      // Create a copy of the queue array and add the new ticket to it
      let queue = [...payload.queue]
      queue.push(payload.newTicket)

      // Set the updated queue in the Firebase Realtime Database
      set(reference, queue)
    },

    getServiceDetails: ({ commit }, payload) => {
      const db = getDatabase()
      const reference = ref(db, 'services/' + payload.key)

      // Attach an event listener to the reference
      onValue(reference, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();

          if (data.length > 0) {
            // Update the service data in the store
            commit('SET_SERVICE_DATA', { service: payload.service, theme: payload.theme, queue: data })

          } else {
            // Update the service data with an empty queue in the store
            commit('SET_SERVICE_DATA', { service: payload.service, theme: payload.theme, queue: [] })
          }

        } else {
          // Update the service data with an empty queue in the store
          commit('SET_SERVICE_DATA', { service: payload.service, theme: payload.theme, queue: [] })
        }

      })
    },

    updateQueue: ({ commit }, payload) => {
      // Set the "isNext" value to false for the current service key
      commit('SET_NEXT', { key: payload.key, value: false })
      // Get a reference to the Firebase database
      const db = getDatabase()
      // Get references to the 'services' and 'nowServing' paths in the database
      const referenceOne = ref(db, 'services/' + payload.key)
      const referenceTwo = ref(db, 'nowServing/')

      // Create a copy of the currentQueue array
      let currentQueue = [...payload.currentQueue]
      // Create an array to store the updated queue
      let updatedQueue = []

      // Iterate over the currentQueue array
      // If the entry's code is not equal to the current client's code, add it to the updatedQueue array
      currentQueue.forEach((entry) => {
        if (entry.code !== payload.currentClient.code) {
          updatedQueue.push(entry)
        }
      })

      // Update the 'services' path in the database with the updatedQueue array
      set(referenceOne, updatedQueue)

      // Listen for changes on the 'nowServing' path in the database
      // If the snapshot exists, retrieve the data and commit it to the store
      // Otherwise, log a message indicating that the data doesn't exist
      onValue(referenceTwo, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          commit('SET_CURRENT_CLIENT_DETAILS', { client: data[payload.key].client, isServing: data[payload.key].isServing, isNext: data[payload.key].isServing, service: payload.key })
        } else {
          // console.log("Don't exist")
        }
      })

    },

    setNoCurrentClient: ({ commit }, payload) => {
      const db = getDatabase()
      const reference = ref(db, 'nowServing/' + payload.key)
      set(reference, { client: null, isServing: false, isNext: false })

    },

    getAllQueueData: ({ commit }) => {
      // Array of services with their names, themes, and keys
      const services = [
        {
          serviceName: 'Doctor 1',
          theme: '#3A1078',
          key: 'doctorOne'
        },
        {
          serviceName: 'Doctor 2',
          theme: '#4E31AA',
          key: 'doctorTwo'
        },
        {
          serviceName: 'Dental',
          theme: '#2F58CD',
          key: 'dental'
        },
        {
          serviceName: 'Other',
          theme: '#3795BD',
          key: 'other'
        }
      ]
      const db = getDatabase()
      const reference = ref(db, 'services/')

      // Listen for changes in the database reference
      onValue(reference, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();

          // Iterate over each service
          services.forEach(service => {
            // Check if data exists for the service key
            if (data[service.key]) {
              // Update the state with service data and queue
              commit('SET_SERVICE_DATA', { service: service.serviceName, theme: service.theme, queue: data[service.key] })
              commit('SET_ALL_QUEUE_DATA', { service: service.serviceName, queue: data[service.key] })

            } else {
              // If no data exists for the service key, update the state with an empty queue
              commit('SET_SERVICE_DATA', { service: service.serviceName, theme: service.theme, queue: [] })
              commit('SET_ALL_QUEUE_DATA', { service: service.serviceName, queue: [] })

            }

          })

        } else {
          // If the snapshot does not exist, update the state with empty queues for all services
          services.forEach(service => {

            commit('SET_SERVICE_DATA', { service: service.serviceName, theme: service.theme, queue: [] })
            commit('SET_ALL_QUEUE_DATA', { service: service.serviceName, queue: [] })

          })
        }
      });
    },

    // Retrieves the details of the next client from the 'nowServing' node in the database and commits the data to the store.
    getNextClient: ({ commit }, payload) => {
      const db = getDatabase()
      const reference = ref(db, 'nowServing/')

      // Check if a client is specified in the payload
      if (payload.client !== null) {

        // Listen for changes in the 'nowServing' node
        onValue(reference, (snapshot) => {

          // Check if the snapshot of the data exists
          if (snapshot.exists()) {

            const data = snapshot.val();
            // Commit the extracted client details to the store
            commit('SET_CURRENT_CLIENT_DETAILS', { client: data[payload.key].client, isServing: data[payload.key].isServing, isNext: data[payload.key].isServing, service: payload.key })
          } else {
            // console.log("Don't exist")
          }
        })
      }
    },

    getAllNowServing: ({ commit }) => {
      // Define an array of services with their details
      const services = [
        {
          serviceName: 'Doctor 1',
          theme: '#3A1078',
          key: 'doctorOne'
        },
        {
          serviceName: 'Doctor 2',
          theme: '#4E31AA',
          key: 'doctorTwo'
        },
        {
          serviceName: 'Dental',
          theme: '#2F58CD',
          key: 'dental'
        },
        {
          serviceName: 'Other',
          theme: '#3795BD',
          key: 'other'
        }
      ]
      const db = getDatabase()
      const reference = ref(db, 'nowServing/')

      // Listen for changes in the 'nowServing/' reference
      onValue(reference, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          // Iterate over each service
          services.forEach(service => {
            if (data[service.key]) {

              // If data for the service exists, commit the details to the store
              commit('SET_CURRENT_CLIENT_DETAILS', { client: data[service.key].client, isServing: data[service.key].isServing, isNext: data[service.key].isServing, service: service.key })

            } else {
              // If data for the service does not exist, commit null values to the store
              commit('SET_CURRENT_CLIENT_DETAILS', { client: null, isServing: false, isNext: false, service: service.key })

            }

          })

        } else {
          // console.log("Don't exist")
        }
      });
    },

    setNextClient: ({ commit }, payload) => {
      const db = getDatabase()
      const reference = ref(db, 'nowServing/' + payload.key)

      if (payload.client !== null) {
        set(reference, { client: payload.client, isServing: true, isNext: true })
      }
    },

    setBaseURL: async ({ commit }, payload) => {
      const db = getDatabase()
      const reference = ref(db, 'baseURL/')

      return new Promise((resolve, reject) => {
        try {
          // Attach an event listener to the reference
          onValue(reference, (snapshot) => {
            if (snapshot.exists()) {
              const data = snapshot.val();

              // Update the base URL in the store using commit
              commit('SET_BASE_URL', { url: data.url, isValid: data.isValid })
              resolve()  // Resolve the promise to indicate success
            } else {

              // If the snapshot does not exist, set default values for base URL
              commit('SET_BASE_URL', { url: 'http://localhost', isValid: false })
              resolve()  // Resolve the promise to indicate success
            }
          })
        } catch (error) {
          reject(error)  // Reject the promise if an error occurs
        }
      })
    },

    updateBaseURL: ({ commit }, payload) => {
      const db = getDatabase()
      const reference = ref(db, 'baseURL/')
      set(reference, payload)
    },

    loginUser: ({ commit }, payload) => {

      // Make a POST request to the login API endpoint with the provided payload
      fetch(payload.url + '/api/login', {
        method: 'POST',
        body: JSON.stringify({
          username: payload.credentials.username,
          password: payload.credentials.password
        }),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then(async (response) => {
          const data = await response.json()

          // Check if the message in the response indicates successful login
          if (data.message !== 'Wrong username or password') {

            // Store the token and user name in the browser's local storage
            localStorage.setItem('token', data.token)
            localStorage.setItem('user', data.user.name)

            // Redirect the user to the 'Dashboard' route
            router.push({ name: 'Display Board' })
          } else {
            // Display an alert message indicating the wrong username or password
            alert(data.message)
          }
        })
        .catch(error => {
          // Display an alert message indicating the wrong username or password
          alert('Wrong username or password')
          console.log(error.message)
        })
    },

    logoutUser: ({ commit }, payload) => {

      // Sending a POST request to the logout endpoint
      fetch(payload.url + '/api/logout', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'), // Including the token from localStorage in the request header
          'Accept': 'application/json' // Specifying the desired response format
        }
      })
        .then(async (response) => {
          const data = await response.json()
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          router.push({ name: 'Login' })
        })
        .catch(error => {
          console.log(error);
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          router.push({ name: 'Login' })
        })
    },

    saveClientToAPI: ({ commit }, payload) => {
      fetch(payload.url + '/api/appointments', {
        method: 'POST',

        // Convert the client data to JSON format and include it in the request body
        body: JSON.stringify({
          ticket_type: payload.client.code,
          name: payload.client.name,
          age: payload.client.age,
          gender: payload.client.gender,
          appointment_type: payload.client.appointmentType,
          priority_type: payload.client.priorityType
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Accept': 'application/json'
        },
      })
        .then(async (response) => {
          const data = await response.json()
        })
        .catch(error => {
          console.log(error)
        })
    },

    //IN PROGRESS

    getClientsFromAPI: ({ commit }, payload) => {
      fetch(payload.url + '/api/appointments', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Accept': 'application/json',
        },
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Request failed with status: ' + response.status);
          }
        })
        .then(data => {
          // Handle the fetched data
          console.log(data);
        })
        .catch(error => {
          // Handle the error
          console.log(error.message);
        });
    }
  },
  modules: {

  }
})
