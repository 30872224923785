<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" sm="6" md="3" v-for="(queue, index) in singleServiceQueue" :key="index">
                <SingleQueue :queue="queue"></SingleQueue>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex'
import SingleQueue from '../DisplayBoard/SingleQueue'

export default {
    name: 'DisplayBoard',

    components: {
        SingleQueue,
    },
    data: () => ({

    }),
    methods: {
        ...mapActions(['getAllQueueData'])
    },
    computed: {
        ...mapGetters(['getServices', 'getCurrentClient']),

        singleServiceQueue() {
            let services = this.getServices
            let currentClient
            let withPriorityClients = []
            let withoutPriorityClients = []
            let queues = []

            services.forEach(service => {
                switch (service.service) {
                    case 'Doctor 1':
                        currentClient = this.getCurrentClient.doctorOne.client
                        break;

                    case 'Doctor 2':
                        currentClient = this.getCurrentClient.doctorTwo.client
                        break;

                    case 'Dental':
                        currentClient = this.getCurrentClient.dental.client
                        break;

                    case 'Other':
                        currentClient = this.getCurrentClient.other.client
                        break;

                    default:
                        break;
                }

                service.queue.forEach(entry => {
                    if (entry !== null) {
                        if (entry.priorityType === 'None') {
                            if (currentClient === null || currentClient === undefined) {
                                withoutPriorityClients.push(entry)
                            } else {
                                if (currentClient !== undefined && entry.code !== currentClient.code) {
                                    withoutPriorityClients.push(entry)
                                }
                            }
                        } else {
                            if (currentClient === null || currentClient === undefined) {
                                withPriorityClients.push(entry)
                            } else {
                                if (currentClient !== undefined && entry.code !== currentClient.code) {
                                    withPriorityClients.push(entry)
                                }
                            }
                        }
                    }
                })

                queues.push({
                    service: service.service,
                    theme: service.theme,
                    currentClient: currentClient,
                    queue: [...withPriorityClients, ...withoutPriorityClients]
                })

                withPriorityClients = []
                withoutPriorityClients = []
            })
            return queues
        }
    },
    created() {

    }
}
</script>
  