// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { getDatabase, ref, onValue } from "firebase/database";
import 'firebase/compat/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyADrQWPqq4D5mJwCRJMeBT9_ruBTECpXIg",
    authDomain: "qms-jaen.firebaseapp.com",
    databaseURL: "https://qms-jaen-default-rtdb.firebaseio.com",
    projectId: "qms-jaen",
    storageBucket: "qms-jaen.appspot.com",
    messagingSenderId: "1055010987903",
    appId: "1:1055010987903:web:39fb9a9874ac928b50844a",
    databaseURL: "https://qms-jaen-default-rtdb.firebaseio.com/"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// const db = app.firestore();
const db = getDatabase();

export { db }
