<template>
    <div>
        <v-card flat :color="queue.theme" class="d-flex flex-column align-center">
            <v-card-subtitle class="text-h6 white--text">{{ queue.service.toUpperCase() }}</v-card-subtitle>
            <v-card-text class="text-h2 white--text text-center pb-0" v-if="queue.currentClient">{{ queue.currentClient.code
            }}</v-card-text>
            <v-card-text class="text-h2 white--text text-center pb-0" v-if="!queue.currentClient">----</v-card-text>
            <v-card-subtitle class="white--text pt-0"
                v-if="queue.currentClient && queue.currentClient.priorityType !== 'None'">{{
                    queue.currentClient.priorityType.toUpperCase() }}</v-card-subtitle>
            <v-card-subtitle class="white--text pt-0"
                v-if="queue.currentClient && queue.currentClient.priorityType === 'None' || !queue.currentClient">----</v-card-subtitle>
            <v-card-subtitle class="white--text pt-0" v-if="queue.currentClient">{{ queue.currentClient.name.toUpperCase()
            }}</v-card-subtitle>
            <v-card-subtitle class="white--text pt-0" v-if="!queue.currentClient">NONE</v-card-subtitle>
        </v-card>
        <v-card flat outlined v-for="(ticket, index) in queue.queue" :key="index" class="mt-4">
            <v-card-title class="text-h5">{{ ticket.priorityType !== 'None' ? ticket.code + '-' + ticket.priorityType :
                ticket.code }}</v-card-title>
            <v-card-subtitle>{{ ticket.name.toUpperCase() }}</v-card-subtitle>
        </v-card>
    </div>
</template>
  
<script>

export default {
    name: 'SingleQueue',
    props: {
        queue: Object,
    }
}
</script>
  