<template>
    <router-view></router-view>
</template>
  
<script>

export default {
    name: 'Ticket',

    data: () => ({
        
    })
}
</script>
  