import { render, staticRenderFns } from "./DashboardView.vue?vue&type=template&id=106bbe7f&scoped=true&"
import script from "./DashboardView.vue?vue&type=script&lang=js&"
export * from "./DashboardView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "106bbe7f",
  null
  
)

export default component.exports