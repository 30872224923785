import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardView from '../views/Dashboard/DashboardView'
import TicketView from '../views/Ticket/TicketView'
import Login from '../views/Authentication/Login'
import Register from '../views/Authentication/Register'
import DisplayBoardView from '../views/DisplayBoard/DisplayBoardView'
import TicketDetails from '../views/Ticket/TicketDetails'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      requiresGuest: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresAuth: false,
      requiresGuest: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: {
      requiresAuth: true,
      requiresGuest: false
    }
  },
  {
    path: '/ticket',
    name: 'Ticket',
    component: TicketView,
    children: [
      {
        path: 'doctor-one',
        name: 'Ticket (Doctor One)',
        component: TicketDetails,
        meta: {
          name: 'Doctor 1',
          theme: '#3A1078',
          firebaseKey: 'doctorOne',
          requiresAuth: true,
          requiresGuest: false
        }
      },
      {
        path: 'doctor-two',
        name: 'Ticket (Doctor Two)',
        component: TicketDetails,
        meta: {
          name: 'Doctor 2',
          theme: '#4E31AA',
          firebaseKey: 'doctorTwo',
          requiresAuth: true,
          requiresGuest: false
        }
      },
      {
        path: 'dental',
        name: 'Ticket (Dental)',
        component: TicketDetails,
        meta: {
          name: 'Dental',
          theme: '#2F58CD',
          firebaseKey: 'dental',
          requiresAuth: true,
          requiresGuest: false
        }
      },
      {
        path: 'other',
        name: 'Ticket (Other)',
        component: TicketDetails,
        meta: {
          name: 'Other',
          theme: '#3795BD',
          firebaseKey: 'other',
          requiresAuth: true,
          requiresGuest: false
        }
      }
    ]
  },
  {
    path: '/display-board',
    name: 'Display Board',
    component: DisplayBoardView,
    meta: {
      requiresAuth: true,
      requiresGuest: false
    }
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token')
  if(to.meta.requiresAuth){
    if(isAuthenticated === null || isAuthenticated === undefined){
      next({name: 'Login'})
    }else{
      next()
    }
  }else{
    if(isAuthenticated){
      next({name: 'Display Board'})
    }else{
      next()
    }
  }
})

export default router
