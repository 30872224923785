<template>
    <v-card outlined flat>
        <v-card-title>
            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" single-line></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="items" :search="searchItem"></v-data-table>
    </v-card>
</template>
  
<script>

export default {
    name: 'DataTable',
    props: [
        'headers',
        'items',
    ],
    data: () => ({
        searchItem: null
    })
}
</script>
  