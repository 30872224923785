<template>
    <v-container>
        <p class="subtitle-1 font-weight-bold mt-4">{{ $route.meta.name.toUpperCase() }} APPOINTMENTS</p>
        <v-row>
            <v-col cols="12" md="9" class="order-last order-md-first">
                <DataTable :headers="headers" :items="serviceQueue"></DataTable>
            </v-col>
            <v-col cols="12" md="3" class="order-first order-md-last">
                <QueueDetail :clients="currentClients"></QueueDetail>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '../../components/DataTable'
import QueueDetail from '../Ticket/QueueDetail.vue'

export default {
    name: 'Ticket',

    components: {
        DataTable,
        QueueDetail,
    },

    data: () => ({
        serviceName: '',
        headers: [
            {
                text: 'Name',
                value: 'name'
            },
            {
                text: 'Age',
                value: 'age'
            },
            {
                text: 'Gender',
                value: 'gender'
            },
            {
                text: 'Appointment',
                value: 'appointmentType'
            },
            {
                text: 'Ticket',
                value: 'code'
            },
            {
                text: 'Priority',
                value: 'priorityType'
            },
            {
                text: 'Actions',
                value: 'actions'
            }
        ],

    }),
    computed: {
        ...mapGetters(['getServices', 'getAllQueues', 'getIsNext', 'getCurrentClient']),

        serviceQueue() {
            let items = []
            switch (this.$route.meta.name) {
                case 'Doctor 1':
                    items = this.getAllQueues.doctorOne
                    break;

                case 'Doctor 2':
                    items = this.getAllQueues.doctorTwo
                    break;

                case 'Dental':
                    items = this.getAllQueues.dental
                    break;

                case 'Other':
                    items = this.getAllQueues.other
                    break;

                default:
                    break;
            }

            return items
        },

        currentClients() {
            let withoutPriorityQueue = []
            let withPriorityQueue = []
            let queue = []
            let nowClientServing = this.getCurrentClient[this.$route.meta.firebaseKey]
            let isNext

            switch (this.$route.meta.name) {
                case 'Doctor 1':
                    queue = this.getAllQueues.doctorOne
                    isNext = this.getCurrentClient.doctorOne.isServing
                    break;

                case 'Doctor 2':
                    queue = this.getAllQueues.doctorTwo
                    isNext = this.getCurrentClient.doctorTwo.isServing
                    break;

                case 'Dental':
                    queue = this.getAllQueues.dental
                    isNext = this.getCurrentClient.dental.isServing
                    break;

                case 'Other':
                    queue = this.getAllQueues.other
                    isNext = this.getCurrentClient.other.isServing
                    break;

                default:
                    break;
            }

            if (queue.length >= 2) {
                queue.forEach(entry => {
                    if (entry.priorityType === 'PWD' || entry.priorityType === 'Senior Citizen') {
                        withPriorityQueue.push(entry)
                    } else {
                        withoutPriorityQueue.push(entry)
                    }
                })

                if (withPriorityQueue.length >= 2) {
                    if (nowClientServing.isServing === true) {
                        return {
                            currentClient: nowClientServing.client,
                            nextClient: withPriorityQueue[1],
                            isNext: isNext
                        }
                    }
                    return {
                        currentClient: withPriorityQueue[0],
                        nextClient: withPriorityQueue[1],
                        isNext: isNext
                    }

                } else if (withPriorityQueue.length === 1) {
                    if (nowClientServing.isServing === true) {
                        return {
                            currentClient: nowClientServing.client,
                            nextClient: withPriorityQueue[0],
                            isNext: isNext
                        }
                    }
                    return {
                        currentClient: withPriorityQueue[0],
                        nextClient: withoutPriorityQueue[0],
                        isNext: isNext
                    }
                } else {
                    if (nowClientServing.isServing === true) {
                        return {
                            currentClient: nowClientServing.client,
                            nextClient: withoutPriorityQueue[1],
                            isNext: isNext
                        }
                    }
                    return {
                        currentClient: withoutPriorityQueue[0],
                        nextClient: withoutPriorityQueue[1],
                        isNext: isNext
                    }
                }

            } else if (queue.length === 1) {
                if (nowClientServing.isServing === true) {
                    return {
                        currentClient: nowClientServing.client,
                        nextClient: null,
                        isNext: isNext
                    }
                }
                return {
                    currentClient: queue[0],
                    nextClient: null,
                    isNext: isNext
                }
            } else {
                if (nowClientServing.isServing === true) {
                    return {
                        currentClient: nowClientServing.client,
                        nextClient: null,
                        isNext: isNext
                    }
                }
                return {
                    currentClient: null,
                    nextClient: null,
                    isNext: isNext
                }
            }
        }
    },
    methods: {
        ...mapActions(['getServiceDetails'])
    }
}
</script>
  